<template>
  <div class="my_courses_block">
    <div class="my_courses_header_block">
      <div class="my_courses_header_container">
        <div class="my_courses_header_label">
          {{ $t("My_courses") }}
        </div>
      </div>
    </div>
    <div class="my_courses_main_block">
      <div class="my_courses_main_container">
        <div class="my_courses_filter_container">
          <div class="my_courses_filter_label">{{ $t("Sort_and_filter") }}</div>
          <div class="my_courses_status">
            <router-link
              :to="{ name: 'my_courses', params: { type: 'active' } }"
              :class="{
                my_courses_status_active: type === 'active',
                my_courses_status_done: type !== 'active',
              }"
            >
              {{ $t("Active") }}
            </router-link>
            <router-link
              :to="{ name: 'my_courses', params: { type: 'passed' } }"
              :class="{
                my_courses_status_active: type === 'passed',
                my_courses_status_done: type !== 'passed',
              }"
            >
              {{ $t("Passed") }}
            </router-link>
            <router-link
              :to="{ name: 'my_courses', params: { type: 'all' } }"
              :class="{
                my_courses_status_active: type === 'all',
                my_courses_status_done: type !== 'all',
              }"
            >
              {{ $t("All") }}
            </router-link>
          </div>
          <!--          <div class="my_courses_list_sorting">-->
          <!--            <div class="my_courses_list_sorting_label" @click="showSorting = !showSorting">{{ $t('Sorting') }}</div>-->
          <!--            <div class="my_courses_list_radio_block" v-show="showSorting">-->
          <!--              <div class="my_courses_radio_item">-->
          <!--                <input type="radio" name="sort" id="newest" class="my_courses_list_radio" disabled/>-->
          <!--                <label for="newest" class="my_courses_radio_label">{{ $t('By_pass_percentage') }}</label>-->
          <!--              </div>-->
          <!--              <div class="my_courses_radio_item">-->
          <!--                <input type="radio" name="sort" id="popular" class="my_courses_list_radio" disabled/>-->
          <!--                <label for="popular" class="my_courses_radio_label">{{ $t('End') }}</label>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="my_courses_list_sorting">-->
          <!--            <div class="my_courses_list_sorting_label" @click="showCourseDuration = !showCourseDuration">{{ $t('Course_duration') }}</div>-->
          <!--            <div class="my_courses_list_radio_block" v-show="showCourseDuration">-->
          <!--              <div class="my_courses_radio_item">-->
          <!--                <input type="radio" name="filter" id="8" class="my_courses_list_radio" disabled/>-->
          <!--                <label for="8" class="my_courses_radio_label">8</label>-->
          <!--              </div>-->
          <!--              <div class="my_courses_radio_item">-->
          <!--                <input type="radio" name="filter" id="36" class="my_courses_list_radio" disabled/>-->
          <!--                <label for="36" class="my_courses_radio_label">36</label>-->
          <!--              </div>-->
          <!--              <div class="my_courses_radio_item">-->
          <!--                <input type="radio" name="filter" id="40" class="my_courses_list_radio" disabled/>-->
          <!--                <label for="40" class="my_courses_radio_label">40</label>-->
          <!--              </div>-->
          <!--              <div class="my_courses_radio_item">-->
          <!--                <input type="radio" name="filter" id="72" class="my_courses_list_radio" disabled/>-->
          <!--                <label for="72" class="my_courses_radio_label">72</label>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="my_courses_active_list">
          <div class="my_courses_list_wrapper">
            <div
              class="courses_list_item"
              v-for="(course, key) in sortingCourses"
              :key="key"
            >
              <div
                class="courses_list_item_img"
                :style="'background-image: url(' + course.preview_image + ')'"
              ></div>
              <div class="courses_list_item_desc">
                <div class="courses_list_item_desc_text mobile_my_courses">
                  <div class="courses_list_item_title">{{ course.title }}</div>
                  <div class="courses_list_item_bar">
                    <div
                      class="courses_list_item_back"
                      :style="{ width: getProgressWidth(course.progress) }"
                    ></div>
                    <div
                      class="courses_list_item_process"
                      :style="{
                        color: course.progress > 0 ? '#FFFFFF' : '#305AA6',
                      }"
                    >
                      {{
                        course.progress === 100
                          ? $t("Completed")
                          : $t("In_process")
                      }}
                    </div>
                    <div
                      class="courses_list_item_result"
                      :class="
                        course.progress > 85
                          ? 'm-right-' + (100 - course.progress)
                          : ''
                      "
                      :style="{
                        color: course.progress > 85 ? '#FFFFFF' : '#305AA6',
                      }"
                    >
                      {{ course.progress }}%
                    </div>
                  </div>
                  <div class="courses_list_item_date">
                    <div class="my_courses_list_item_start">
                      {{ $t("Start") }}: 22.04.22
                    </div>
                    <div class="my_courses_list_item_between">|</div>
                    <div class="my_courses_list_item_end">
                      {{ $t("Ending") }}: 30.05.22
                    </div>
                  </div>
                  <div
                    class="my_courses_list_item_action"
                    v-if="course.progress !== 100"
                  >
                    <router-link
                      :to="{
                        name: 'about-course',
                        params: { slug: course.slug },
                      }"
                      class="my_courses_list_item_continue"
                    >
                      {{ $t("Proceed") }}
                      <div class="my_courses_list_item_arrow"></div>
                    </router-link>
                    <!--                  <div @click.prevent="unsubscribe(course.slug)" class="my_courses_list_item_unsubscribe">-->
                    <!--                    {{ $t('Unsubscribe') }}-->
                    <!--                  </div>-->
                  </div>
                  <!--                <div class="my_courses_download_certificate" v-if="course.progress === 100">{{ $t('download_certificate') }}</div>-->
                  <div
                    class="my_courses_view_course"
                    v-if="course.progress === 100"
                    @click="goToCourse(course.slug)"
                  >
                    {{ $t("view_course") }}
                  </div>
                  <a
                    v-if="course.certificate"
                    download
                    target="_blank"
                    class="download-cert"
                    :href="getCertPath(course.certificate)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M6.71875 8.59375L10 11.875L13.2812 8.59375"
                        stroke="#305AA6"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 3.12305V11.873"
                        stroke="#305AA6"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.5 10.625V16.25C17.5 16.4158 17.4342 16.5747 17.3169 16.6919C17.1997 16.8092 17.0408 16.875 16.875 16.875H3.125C2.95924 16.875 2.80027 16.8092 2.68306 16.6919C2.56585 16.5747 2.5 16.4158 2.5 16.25V10.625"
                        stroke="#305AA6"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Скачать сертификат</span></a
                  >
                </div>
              </div>
            </div>
            <!--          <div class="courses_list_item_empty" v-for="item in 9 - courses.length">-->
            <!--            <div class="courses_list_item_cover">-->
            <!--              <div class="courses_list_item_cover_label">{{ $t('Sign_up_for_a_new_course') }}</div>-->
            <!--            </div>-->
            <!--            <div class="courses_list_item_img">-->
            <!--            </div>-->
            <!--            <div class="courses_list_item_desc">-->
            <!--              <div class="courses_list_item_desc_text">-->
            <!--                <div class="courses_list_item_title">&nbsp;</div>-->
            <!--                <div class="courses_list_item_bar">-->
            <!--                  <div class="courses_list_item_back"></div>-->
            <!--                  <div class="courses_list_item_process">&nbsp;</div>-->
            <!--                  <div class="courses_list_item_result">&nbsp;</div>-->
            <!--                </div>-->
            <!--                <div class="courses_list_item_date">-->
            <!--                  <div class="my_courses_list_item_start">&nbsp;</div>-->
            <!--                  <div class="my_courses_list_item_between">&nbsp;</div>-->
            <!--                  <div class="my_courses_list_item_end">&nbsp;</div>-->
            <!--                </div>-->
            <!--                <div class="my_courses_list_item_action">-->
            <!--                  <a class="my_courses_list_item_continue">-->
            <!--                    &nbsp;-->
            <!--                    <div class="my_courses_list_item_arrow"></div>-->
            <!--                  </a>-->
            <!--                  <div class="my_courses_list_item_unsubscribe">&nbsp;</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
          </div>
          <div class="courses_pagination">
            <MyPagination
              :totalPages="totalPages"
              :total="total"
              :perPage="perPage"
              :currentPage="currentPage"
              @pagechanged="onPageChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyPagination from "@/components/MyPagination";
import Vue from "vue";

export default {
  name: "MyCourses",
  components: {
    MyPagination,
  },
  data() {
    return {
      courses: [],
      sortingCourses: [],
      currentPage: 1,
      perPage: 12,
      total: 0,
      totalPages: 0,
      showCourseDuration: true,
      showSorting: true,
      sortingCoursesStatus: "",
    };
  },
  methods: {
    goToCourse(slug) {
      this.$router.push(`/course/${slug}`);
    },
    unsubscribe(slug) {
      let apiRoot = API_ROOT;
      this.$http
        .post(`${apiRoot}/api/course/unsubscribe/${slug}`)
        .then((res) => {
          if (res.data) {
            Vue.toastr({
              message: "Success",
              description: "Вы успешно отписались от курса",
              type: "success",
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.course.registered = false;
        });
    },
    getCourses(page, status = this.type) {
      let apiRoot = API_ROOT;
      this.$http
        .get(`${apiRoot}/api/courses/my`, { params: { page, status } })
        .then((res) => {
          this.courses = res.body.data;

          this.courses =
            this.$i18n.locale == "ru"
              ? this.courses.filter((course) => course.language == "Русский")
              : this.courses.filter((course) => course.language == "Казахский");
          this.sortingCourses = this.courses;
          this.total = res.body.meta.total;
          this.perPage = res.body.meta.per_page;
          this.totalPages = res.body.meta.last_page;
          this.sortingCoursesStatus = this.$router.history.current.params.type;
          //this.sortingCoursesStatus = 'active'
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    onPageChange(page) {
      this.currentPage = page;
      this.getCourses(page);
    },
    getProgressWidth(progress) {
      if (progress > 0 && progress < 40) progress = 40;
      return progress + "%";
    },
    getCertPath: (path) => `${window.API_ROOT}/storage/${path}`,
  },
  computed: {
    type: function () {
      return this.$route.params.type;
    },
  },
  watch: {
    $route(to, from) {
      this.sortingCoursesStatus = to.params.type;
      this.getCourses(this.currentPage, to.params.type);
    },
  },
  mounted() {
    this.getCourses(this.currentPage);
  },
};
</script>

<style lang="scss" scoped>
.courses_list_item .courses_list_item_desc .courses_list_item_desc_text {
  width: 100% !important;
}
.courses_list_item_date {
  display: none;
}
.m-right-1 {
  margin-right: 1%;
}

.m-right-2 {
  margin-right: 2%;
}

.m-right-3 {
  margin-right: 3%;
}

.m-right-4 {
  margin-right: 4%;
}

.m-right-5 {
  margin-right: 5%;
}

.m-right-6 {
  margin-right: 6%;
}

.m-right-7 {
  margin-right: 7%;
}

.m-right-8 {
  margin-right: 8%;
}

.m-right-9 {
  margin-right: 9%;
}

.m-right-10 {
  margin-right: 10%;
}

.m-right-11 {
  margin-right: 11%;
}

.m-right-12 {
  margin-right: 12%;
}

.m-right-13 {
  margin-right: 13%;
}

.m-right-14 {
  margin-right: 14%;
}

.m-right-15 {
  margin-right: 15%;
}
.download-cert {
  text-align: center;
  margin-top: 1rem;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  background: #d7e5ff;
  border-radius: 8px;
  height: max-content;
  min-height: 40px;
  color: #305aa6;
  font-size: 16px;

  &:hover {
    color: #305aa6;
  }

  svg {
    margin: auto 0 auto 22px;
  }

  span {
    width: 100%;
    display: block;
    margin: auto 0 auto 22px;
  }
}
</style>
