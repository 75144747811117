var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my_courses_block"},[_c('div',{staticClass:"my_courses_header_block"},[_c('div',{staticClass:"my_courses_header_container"},[_c('div',{staticClass:"my_courses_header_label"},[_vm._v(" "+_vm._s(_vm.$t("My_courses"))+" ")])])]),_c('div',{staticClass:"my_courses_main_block"},[_c('div',{staticClass:"my_courses_main_container"},[_c('div',{staticClass:"my_courses_filter_container"},[_c('div',{staticClass:"my_courses_filter_label"},[_vm._v(_vm._s(_vm.$t("Sort_and_filter")))]),_c('div',{staticClass:"my_courses_status"},[_c('router-link',{class:{
              my_courses_status_active: _vm.type === 'active',
              my_courses_status_done: _vm.type !== 'active',
            },attrs:{"to":{ name: 'my_courses', params: { type: 'active' } }}},[_vm._v(" "+_vm._s(_vm.$t("Active"))+" ")]),_c('router-link',{class:{
              my_courses_status_active: _vm.type === 'passed',
              my_courses_status_done: _vm.type !== 'passed',
            },attrs:{"to":{ name: 'my_courses', params: { type: 'passed' } }}},[_vm._v(" "+_vm._s(_vm.$t("Passed"))+" ")]),_c('router-link',{class:{
              my_courses_status_active: _vm.type === 'all',
              my_courses_status_done: _vm.type !== 'all',
            },attrs:{"to":{ name: 'my_courses', params: { type: 'all' } }}},[_vm._v(" "+_vm._s(_vm.$t("All"))+" ")])],1)]),_c('div',{staticClass:"my_courses_active_list"},[_c('div',{staticClass:"my_courses_list_wrapper"},_vm._l((_vm.sortingCourses),function(course,key){return _c('div',{key:key,staticClass:"courses_list_item"},[_c('div',{staticClass:"courses_list_item_img",style:('background-image: url(' + course.preview_image + ')')}),_c('div',{staticClass:"courses_list_item_desc"},[_c('div',{staticClass:"courses_list_item_desc_text mobile_my_courses"},[_c('div',{staticClass:"courses_list_item_title"},[_vm._v(_vm._s(course.title))]),_c('div',{staticClass:"courses_list_item_bar"},[_c('div',{staticClass:"courses_list_item_back",style:({ width: _vm.getProgressWidth(course.progress) })}),_c('div',{staticClass:"courses_list_item_process",style:({
                      color: course.progress > 0 ? '#FFFFFF' : '#305AA6',
                    })},[_vm._v(" "+_vm._s(course.progress === 100 ? _vm.$t("Completed") : _vm.$t("In_process"))+" ")]),_c('div',{staticClass:"courses_list_item_result",class:course.progress > 85
                        ? 'm-right-' + (100 - course.progress)
                        : '',style:({
                      color: course.progress > 85 ? '#FFFFFF' : '#305AA6',
                    })},[_vm._v(" "+_vm._s(course.progress)+"% ")])]),_c('div',{staticClass:"courses_list_item_date"},[_c('div',{staticClass:"my_courses_list_item_start"},[_vm._v(" "+_vm._s(_vm.$t("Start"))+": 22.04.22 ")]),_c('div',{staticClass:"my_courses_list_item_between"},[_vm._v("|")]),_c('div',{staticClass:"my_courses_list_item_end"},[_vm._v(" "+_vm._s(_vm.$t("Ending"))+": 30.05.22 ")])]),(course.progress !== 100)?_c('div',{staticClass:"my_courses_list_item_action"},[_c('router-link',{staticClass:"my_courses_list_item_continue",attrs:{"to":{
                      name: 'about-course',
                      params: { slug: course.slug },
                    }}},[_vm._v(" "+_vm._s(_vm.$t("Proceed"))+" "),_c('div',{staticClass:"my_courses_list_item_arrow"})])],1):_vm._e(),(course.progress === 100)?_c('div',{staticClass:"my_courses_view_course",on:{"click":function($event){return _vm.goToCourse(course.slug)}}},[_vm._v(" "+_vm._s(_vm.$t("view_course"))+" ")]):_vm._e(),(course.certificate)?_c('a',{staticClass:"download-cert",attrs:{"download":"","target":"_blank","href":_vm.getCertPath(course.certificate)}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","viewBox":"0 0 20 20","fill":"none"}},[_c('path',{attrs:{"d":"M6.71875 8.59375L10 11.875L13.2812 8.59375","stroke":"#305AA6","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M10 3.12305V11.873","stroke":"#305AA6","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M17.5 10.625V16.25C17.5 16.4158 17.4342 16.5747 17.3169 16.6919C17.1997 16.8092 17.0408 16.875 16.875 16.875H3.125C2.95924 16.875 2.80027 16.8092 2.68306 16.6919C2.56585 16.5747 2.5 16.4158 2.5 16.25V10.625","stroke":"#305AA6","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',[_vm._v("Скачать сертификат")])]):_vm._e()])])])}),0),_c('div',{staticClass:"courses_pagination"},[_c('MyPagination',{attrs:{"totalPages":_vm.totalPages,"total":_vm.total,"perPage":_vm.perPage,"currentPage":_vm.currentPage},on:{"pagechanged":_vm.onPageChange}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }